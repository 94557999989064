export const AnalyticsIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 48 48"
        height={16}
        width={16}
      >
        <circle
          cx="4"
          cy="27"
          r="3"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></circle>
        <circle
          cx="25"
          cy="18"
          r="3"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></circle>
        <circle
          cx="44"
          cy="31"
          r="3"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        ></circle>
        <path
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M7 24.5L22 17.5"
        ></path>
        <path
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M28 18.5L41 28.5"
        ></path>
      </svg>
    </>
  );
};
