import React from "react";

export const DashboardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.25 8H11.75L9.875 13.625L6.125 2.375L4.25 8H1.75"
        stroke="#707070"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
