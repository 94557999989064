import React, { useState, useEffect } from "react";
import { Box, Skeleton, Tooltip } from "@mui/material";
import { StrokeHelpIcon } from "../../library";
import axios from "@helpers/axios/private.axios";

export const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  console.log(setLoading);

  useEffect(() => {
    const getAnalytics = async () => {
      try {
        setLoading(true);
        const resp = await axios.get("/users/analytics");
        setData(resp.data.data.analytics);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };

    getAnalytics();
  }, []);

  return (
    <div className="main-info dashboard">
      <h1 className="main-info__title">Analytics</h1>
      <section className="dashboard-full-info">
        <Box sx={{ overflowX: "auto" }}>
          <Box sx={{ minWidth: 1065 }} className="table table--mini">
            <div className="table__tr table__tr--head">
              <Box
                sx={{
                  flex: "1 0 72px",
                }}
                className="table__td"
              >
                <p className="table__text">Date</p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 68px",
                }}
                className="table__td"
              >
                <p className="table__text">Visitors</p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 68px",
                }}
                className="table__td"
              >
                <p className="table__text">Registrations</p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 68px",
                }}
                className="table__td"
              >
                <p className="table__text">%CTR</p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 67px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  FTD
                  <Tooltip
                    title="The number of people who signed up using the link + topped up their first deposit (number of clients)"
                    placement="bottom"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 78px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  FTDS
                  <Tooltip
                    title="Total number of first deposits per day, amount in $"
                    placement="top"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 70px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  DPST
                  <Tooltip
                    title="The total number of deposits per day or the number of deposits of a specific client when entering a specific ID"
                    placement="bottom"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 64px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  DPSTS
                  <Tooltip
                    title="The total amount of deposits per day or the total amount of deposits of a specific client when entering ID"
                    placement="top"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 85px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  RVN
                  <Tooltip
                    title="Turnover - total trading turnover of referrals or a specific ID"
                    placement="top"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                    enterTouchDelay={0}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
              <Box
                sx={{
                  flex: "1 0 100px",
                }}
                className="table__td"
              >
                <p className="table__text">
                  RVNS
                  <Tooltip
                    title="Net turnover - the same trading turnover, only with the percentage of platform profits deducted"
                    placement="top"
                    className="tooltip__icon"
                    slotProps={{
                      tooltip: {
                        className: "tooltip__popper",
                      },
                    }}
                    enterTouchDelay={0}
                  >
                    <i className="dashboard-full-info__help">
                      <StrokeHelpIcon />
                    </i>
                  </Tooltip>
                </p>
              </Box>
            </div>
            {loading ? (
              <>
                <div className="table__tr">
                  <Skeleton
                    className="table__skeleton"
                    variant="rounded"
                    width="100%"
                  />
                </div>
                <div className="table__tr">
                  <Skeleton
                    className="table__skeleton"
                    variant="rounded"
                    width="100%"
                  />
                </div>
                <div className="table__tr">
                  <Skeleton
                    className="table__skeleton"
                    variant="rounded"
                    width="100%"
                  />
                </div>
              </>
            ) : data.length ? (
              data.map((el) => (
                <div key={el.id} className="table__tr">
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.formatted_date}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.visitor_count}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.registration_count}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 68px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.ctr}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 67px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.ftd}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 78px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.ftds}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 70px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.dpst}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 64px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.dpsts}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 85px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.rvn}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 100px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{el.rvns}</p>
                  </Box>
                </div>
              ))
            ) : (
              <div className="table__tr table__tr--head">
                <p>Not Found</p>
              </div>
            )}
          </Box>
        </Box>
      </section>
    </div>
  );
};
