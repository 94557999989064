import React from "react";
import { Box } from "@mui/material";
import { CopyIcon } from "@library";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useDispatch } from "react-redux";
import { ALERT } from "../../../../constants";
import config from "../../../../config";

const Campaigns = ({ updateCampaign, updateLink, campaigns }) => {
  console.log(updateCampaign);
  const dispatch = useDispatch();

  const onCopy = () => {
    dispatch({
      type: ALERT.ALERT_SUCCESS,
      payload: "Link copied successfully",
    });
  };

  return (
    <div className="main-info campaigns">
      <h1 className="main-info__title">Campaigns</h1>

      <Box sx={{ overflowX: "auto" }}>
        <Box sx={{ minWidth: 1080 }} className="table">
          <div className="table__tr table__tr--head">
            <Box
              sx={{
                flex: "1 0 100px",
              }}
              className="table__td"
            >
              <p className="table__text">Campaign name</p>
            </Box>
            <Box
              sx={{
                flex: "1 0 110px",
              }}
              className="table__td"
            >
              <p className="table__text">Offer type</p>
            </Box>
            <Box
              sx={{
                flex: "1 0 70px",
              }}
              className="table__td"
            >
              <p className="table__text">% CTR</p>
            </Box>
            <Box
              sx={{
                flex: "1 0 70px",
              }}
              className="table__td"
            >
              <p className="table__text">Promo code</p>
            </Box>
          </div>

          {campaigns.map((item) => {
            const { campaign_name, offer, ctr, id, hash, status, promo_code } =
              item;
            return (
              <div key={id} className="table__tr table__tr--block">
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      flex: "1 0 100px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{campaign_name}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 110px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">
                      {offer.charAt(0).toUpperCase() +
                        offer.slice(1) +
                        " Sharing"}
                    </p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 70px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{ctr}</p>
                  </Box>
                  <Box
                    sx={{
                      flex: "1 0 70px",
                    }}
                    className="table__td"
                  >
                    <p className="table__text">{promo_code}</p>
                  </Box>
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    flex: "1 0 174px",*/}
                  {/*  }}*/}
                  {/*  className="table__td"*/}
                  {/*>*/}
                  {/*  <div className="table__wrap">*/}
                  {/*    <a*/}
                  {/*      href={`https://stage-reviarte.com/p/${hash}?target=register`}*/}
                  {/*      className="table__text table__text--blue"*/}
                  {/*    >*/}
                  {/*      https://stage-reviarte.com/p/{hash}?target=register*/}
                  {/*    </a>*/}
                  {/*    <CopyToClipboard*/}
                  {/*      text={`https://stage-reviarte.com/p/${hash}?target=register`}*/}
                  {/*    >*/}
                  {/*      <button type="button" className="table__copy">*/}
                  {/*        <CopyIcon />*/}
                  {/*      </button>*/}
                  {/*    </CopyToClipboard>*/}
                  {/*  </div>*/}
                  {/*</Box>*/}
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    flex: "1 0 132px",*/}
                  {/*  }}*/}
                  {/*  className="table__td table__td--column"*/}
                  {/*>*/}
                </Box>
                <Box className="table__td" sx={{ flex: "1 0 100%" }}>
                  <div className="campaigns__btns">
                    <CopyToClipboard
                      text={`${config.app_url}/p/${hash}?target=register`}
                      onCopy={onCopy}
                    >
                      <button
                        type="button"
                        className="campaigns__btn campaigns__link-btn"
                      >
                        <CopyIcon />
                        Link to register
                      </button>
                    </CopyToClipboard>
                    <CopyToClipboard
                      onCopy={onCopy}
                      text={`${config.app_url}/p/${hash}?target=site`}
                    >
                      <button
                        type="button"
                        className="campaigns__btn campaigns__link-btn"
                      >
                        <CopyIcon />
                        Link to site
                      </button>
                    </CopyToClipboard>
                    <CopyToClipboard
                      onCopy={onCopy}
                      text={`${config.app_url}/p/${hash}?target=demo`}
                    >
                      <button
                        type="button"
                        className="campaigns__btn campaigns__link-btn"
                      >
                        <CopyIcon />
                        Link to demo
                      </button>
                    </CopyToClipboard>
                    <button
                      type="button"
                      className={`campaigns__btn campaigns__status campaigns__status--${
                        !status ? "green" : "red"
                      }`}
                      onClick={async () => {
                        await updateLink(id, !status);
                      }}
                    >
                      {status ? "Disable link" : "Enable link"}
                    </button>
                    {/*<button*/}
                    {/*  onClick={async () => {*/}
                    {/*    try {*/}
                    {/*      await updateCampaign(campaign_name, !status);*/}
                    {/*    } catch (e) {}*/}
                    {/*  }}*/}
                    {/*  type="button"*/}
                    {/*  className="campaigns__btn campaigns__status"*/}
                    {/*>*/}
                    {/*  {status ? "Disable campaign" : "Enable campaign"}*/}
                    {/*</button>*/}
                  </div>
                </Box>
                {/*</Box>*/}
              </div>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

export default Campaigns;
