import React, { useEffect, useState } from "react";
import axios from "@helpers/axios/private.axios";
import NewLink from "./components/NewLink";
import Campaigns from "./components/Campaigns";

import "./index.sass";

export const AffiliateLinks = () => {
  const [offerTypes, setOfferTypes] = useState([]);
  const [linkTypes, setLinkTypes] = useState([]);
  const [campaignNames, setCampaignNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [campaigns, setCampaigns] = useState([]);

  const getSelectsValues = async () => {
    const resp = await axios.get("/affiliate_links/selects");
    setLoading(false);
    const { data } = resp.data;

    console.log(loadingCampaigns);

    setOfferTypes(data.offerTypes);
    setLinkTypes(data.linkTypes);
    console.log(data.campaignNames, "data.campaignNames");
    setCampaignNames(data.campaignNames);
  };

  const getCampaigns = async (status = "", campaign = "", type = "") => {
    setLoadingCampaigns(true);
    try {
      const params = {};
      if (status !== "") {
        params.status = status;
      }
      if (campaign !== "") {
        params.campaign = campaign;
      }
      if (type !== "") {
        params.type = type;
      }

      const resp = await axios.get("/affiliate_links", {
        params,
      });

      setCampaigns(resp.data.data.campaigns);
      setLoadingCampaigns(false);
    } catch (e) {
      console.error(e);
    }
  };

  const updateLink = async (id, status) => {
    try {
      const resp = await axios.post(`affiliate_links/${id}/update`, { status });

      setCampaigns((campaigns) =>
        campaigns.map((item) => (item.id === id ? resp.data.data : item))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const updateCampaign = async (name, status) => {
    try {
      const resp = await axios.post(`affiliate_links/campaign/${name}`, {
        status,
      });

      if (resp.data) {
        // setCampaigns((campaigns) =>
        //   campaigns.map((item) => (item.id === id ? resp.data.data : item))
        // );
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getSelectsValues();
    getCampaigns();
  }, []);

  return (
    <>
      <div className="affiliateLinks">
        {/*<SmartLink />*/}
        <NewLink
          setCampaigns={setCampaigns}
          offerTypes={offerTypes}
          loading={loading}
          setCampaignNames={setCampaignNames}
        />
        <Campaigns
          updateCampaign={updateCampaign}
          updateLink={updateLink}
          filterCampaigns={getCampaigns}
          campaigns={campaigns}
          linkTypes={linkTypes}
          campaignNames={campaignNames}
          loading={loading}
        />
      </div>
    </>
  );
};
